
  import { Component, Prop } from 'vue-property-decorator'
  import Cell from './cell'
  import { ExpandCellOptions } from '@/components/dataTables/cell/index'
  import { Entity } from '@/entities'
  import { View } from '@/entities/public/Resource/metadata'
  import { Resource } from '@/entities/public'
  import { mapGetters } from 'vuex'
  import { Evaluation } from '@/entities/loans'
  import { Appraisal } from '@/entities/purchase'
  import { Payment } from '@/entities/finance'

@Component({
  computed: {
    ...mapGetters('resources', ['active']),
  },
})
  export default class ExpandCell extends Cell {
  @Prop({ type: Function }) readonly expand!: (active: boolean) => void;
  @Prop({ type: Boolean, default: true }) readonly isExpanded!: boolean;

  declare item: Entity
  declare options: ExpandCellOptions

  active!: Resource

  get icon () {
    return this.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'
  }

  get closingReason () {
    const { item } = this

    if (item instanceof Evaluation) {
      return Boolean(item?.financing?.saleOrder?.deal?.closingReason)
    }

    if (item instanceof Appraisal || item instanceof Payment) {
      return Boolean(item?.closingReason)
    }

    return false
  }

  get isExpandOff () {
    const { options } = this
    if (!options) return false

    const { expandOff = false } = options
    return expandOff
  }

  get editOff () {
    const { options } = this
    if (!options) return false

    const { editOff = false } = options

    return editOff
  }

  get show () {
    const { options } = this

    const { show = false } = options

    return show
  }

  get removeOff () {
    const { options } = this
    if (!options) return false

    const { removeOff = false } = options
    return removeOff
  }

  get hideActions () {
    const { editOff, removeOff, isEdit, isClosed } = this

    return !(editOff && removeOff) || !(isEdit && isClosed)
  }

  triggerEditOptions (item) {
    const { options } = this
    if (!options) throw new Error('Option is not mapped in ExpandCell header')

    const { action } = options
    if (!action?.edit) {
      throw new Error('Edit trigger is not mapped in ExpandCell header')
    }

    return action.edit(item)
  }

  triggerRemoveOptions (item) {
    const { options } = this
    if (!options) throw new Error('Option is not mapped in ExpandCell header')

    const { action } = options
    if (!action?.remove) {
      throw new Error('Remove trigger is not mapped in ExpandCell header')
    }

    return action.remove(item)
  }

  get isClosed (): boolean {
    const { metadata } = this

    return Boolean(metadata?.forms?.delete)
  }

  get isEdit (): boolean {
    const { metadata: { forms } } = this

    return Boolean(forms?.update?.length)
  }

  get model (): string {
    const { metadata: { query: { model } } } = this

    return model
  }

  get metadata (): View {
    const { active: { metadata } } = this

    return metadata as View
  }

  get enableEdit () {
    const { editOff, isEdit, show, closingReason } = this

    if (show && closingReason) {
      return false
    }

    return (editOff || isEdit)
  }
  }
